import { toggleMenu } from "./header";
import { scrollToHash, getSamePageAnchor } from "./smooth-scrolling";
import '../css/main.css';


document.addEventListener("DOMContentLoaded", () => {
  /**
   * Toggle del header
   */
  toggleMenu()

  /**
   * Carousel 1 item
   */
  const carousel = document.querySelectorAll(".carousel");
  if (carousel.length > 0) {
    import("./carousel").then((module) => {
      carousel.forEach((slider) => {
        module.carousel(slider);
      });
    });
  }

  /**
   * Carousel 3 item
   */
  const carousel3 = document.querySelectorAll(".carousel-3");
  if (carousel3.length > 0) {
    import("./carousel-3").then((module) => {
      carousel3.forEach((slider) => {
        module.carousel(slider);
      });
    });
  }

  /**
   * Smooth scrolling support
   */
  // If a link's href is within the current page, scroll to it instead
  document.querySelectorAll('a[href]').forEach(a => {
    a.addEventListener('click', e => {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash);

  /**
   * ACF7 event listener
   */
  document.addEventListener('wpcf7mailsent', (event) => {
    gtag('event', 'Formulario', {
      'event_category': event.detail.contactFormId,
      'event_label': event.detail.unitTag
    });
  }, false);

  /**
   * Sticky sidebar
   */
  const progressList = document.querySelector('#progressList');
  if (progressList) {
    import("./sticky-sidebar").then((module) => {
      module.postProgressBar(progressList);
    });
  }
});



